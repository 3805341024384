import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { showModalForm } from '../../actions/modalForm';
import { setIntegrations } from '../../actions/integrations';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import Api from '../../api/api';
import ToggleSwitch from './ToggleSwitch';
import ModalBasicAuth from './ModalBasicAuth';
import ModalConfig from './ModalConfig';

import fetch from 'node-fetch';

function IntegrationAction(props) {
	const { name, description, category, icon } = props.data.display;
	const { type, hasUserConfig } = props.data;

	const [isProcessing, setIsProcessing] = useState(false);
	const [status, setStatus] = useState(props.data.status);
	const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);

	const api = new Api();

	function openConfigModal() {
		setIsConfigModalOpen(true);
	}
	function closeConfigModal() {
		setIsConfigModalOpen(false);
		setIsProcessing(false);
	}

	const enableModalForm = () => {
		const requestType = getRequestType();

		props.dispatch(showModalForm(true, props.data.id, requestType, props.data.type, props.data.config));
	};

	const getRequestType = () => {
		let type;
		switch (props.data.status) {
			case 'NEEDS_CONFIG':
				type = 'INITIAL';
				break;
			case 'ENABLED':
				type = 'CONFIGURE';
				break;
			default:
				type = 'AUTHORIZE';
		}
		return type;
	};

	const getSampleData = async () => {
		props.history.push(`/data/${props.data.id}`);
	};

	// const getSampleData = async () => {
	// 	const response = await fetch('https://slack.com/api/chat.postMessage', {
	// 		method: 'POST',
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 			Accept: 'application/json',
	// 		},
	// 		body: {
	// 			token: 'xoxb-1143969874065-4176817627762-t63QyGysuWpk23vObkRKkB3u',
	// 			channel: 'legal',
	// 			text: 'https://demo.ironcladapp.com/workflow/62ec0edf940ca1ed537f9210/document/Lhw9RwB4Km?v1=1',
	// 			unfurl_links: true,
	// 		},
	// 	});
	// 	console.log(response);
	// };

	return (
		<>
			<div className="flex flex-nowrap p-4 bg-white rounded-lg shadow-xs mt-8">
				<img
					className="mr-2 w-[40px] h-[40px] rounded-lg"
					src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/jqeacbadxfgjkjctehtb"
				/>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					strokeWidth={1.5}
					stroke="currentColor"
					className="w-5 h-5 mt-2"
				>
					<path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
				</svg>

				<img
					className="ml-2 mr-6 w-[40px] h-[40px] rounded-lg"
					src="https://a.slack-edge.com/80588/marketing/img/meta/slack_hash_256.png"
				/>
				<div className="pr-1 overflow-hidden">
					<p className="w-full text-lg font-semibold text-gray-700 truncate ...">{description}</p>
					<p>
						<span className="px-2 py-1 rounded-md bg-purple-600 text-[10px] font-medium text-white">Instant</span>
					</p>
				</div>
				<div className="ml-auto content-center">
					<div className="">
						<button
							onClick={openConfigModal}
							className="px-3 py-2 text-xs font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
						>
							{isConfigModalOpen ? (
								<svg className="animate-spin h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
									<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
									<path
										className="opacity-75"
										fill="currentColor"
										d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
									></path>
								</svg>
							) : (
								'Activate'
							)}
						</button>
					</div>
				</div>
			</div>

			{isConfigModalOpen ? (
				<ModalConfig
					isConfigModalOpen={isConfigModalOpen}
					closeConfigModal={closeConfigModal}
					connectMock={connectMock}
					name={name}
					type={type}
				></ModalConfig>
			) : null}
		</>
	);
}

function mapStateToProps({ auth, integrations }) {
	console.log(`integrations: ${JSON.stringify(integrations)}`);
	return {
		authToken: auth.token,
		integrations,
	};
}

export default withRouter(connect(mapStateToProps)(IntegrationAction));
